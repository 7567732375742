import { Routes } from '@angular/router';
import { AuthGuard } from '../../guards/auth/auth.guard';
import { StructureInfoGuard } from '../../guards/structure-info/structure-info.guard';
import { StructureFormGuard } from '../../guards/structure-form/structure-form.guard';
import { DesktopGuard } from '../../guards/desktop-guard/desktop.guard';

export const structureRoutes: Routes = [
  {
    path: 'structure',
    canActivate: [AuthGuard],
    children: [
      {
        path: 'add-structure',
        loadComponent: () => import('./add-structure/add-structure.page').then( m => m.AddStructurePage)
      },
      {
        path: ':structureId',
        canActivate: [StructureInfoGuard],
        children: [
          {
            path: '',
            loadComponent: () => import('./structure/structure.page').then((m) => m.StructurePage),
          },
          {
            path: 'edit-main-info',
            loadComponent: () => import('./edit-main-info/edit-main-info.page').then((m) => m.EditMainInfoPage),
          },
          {
            path: 'edit-admin-info',
            loadComponent: () => import('./edit-admin-info/edit-admin-info.page').then((m) => m.EditAdminInfoPage),
          },
          {
            path: 'edit-pictures',
            loadComponent: () => import('./edit-pictures/edit-pictures.page').then((m) => m.EditPicturesPage),
          },
        ],
      },
      {
        path: '**',
        redirectTo: '',
        pathMatch: 'full',
      },
    ],
  },
  {
    path: 'structures',
    canActivate: [DesktopGuard],
    loadComponent: () => import('./structures/structures.page').then((m) => m.StructuresPage),
  },
];
