import { Injectable } from '@angular/core';
import { StateStoreService } from '../../../services/state-store/state-store.service';
import { Structure } from '@appines/appines_types';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { map } from 'rxjs';

export type StructureState = Structure & { structureLoaded: boolean };
@Injectable({
  providedIn: 'root',
})
export class StructureService extends StateStoreService<StructureState> {
  constructor(
    private http: HttpClient,
    private router: Router,
  ) {
    super();
  }

  setStructureInState(id: string) {
    if (!this.state()?._id || this.state()?._id !== id) {
      this.getStructure(id).subscribe({
        error: (e) => {
          console.log('error: ', e);
          this.router.navigate(['/profile']).then();
        },
      });
    }
  }

  getStructures() {
    const { href } = new URL('/structures', environment.apiUrl);
    return this.http.get<{ data: Structure[] }>(href);
  }

  getStructure(id: string) {
    const { href } = new URL(`/structures/${id}`, environment.apiUrl);
    this.set('structureLoaded', false);

    return this.http.get<Structure>(href).pipe(
      map((data) => {
        this.setState({ ...data, structureLoaded: true });
        return data;
      }),
    );
  }

  mapCallback(data: Object) {
    this.setState({ ...this.state(), ...data });
    return data;
  }

  updateMainInformations({ structureId, fieldsToUpdate: { name, address } }: { structureId: string; fieldsToUpdate: Pick<Structure, 'name' | 'address'> }) {
    const { href } = new URL(`/structures/${structureId}/mainInfos`, environment.apiUrl);
    return this.http.put(href, { name, address }).pipe(map((data) => this.mapCallback(data)));
  }

  updateDescription({ structureId, fieldsToUpdate: { description } }: { structureId: string; fieldsToUpdate: Pick<Structure, 'description'> }) {
    const { href } = new URL(`/structures/${structureId}/description`, environment.apiUrl);
    return this.http.put(href, { description }).pipe(map((data) => this.mapCallback(data)));
  }

  updateAdminInfos({ structureId, fieldsToUpdate: { type, siret, officeType } }: { structureId: string; fieldsToUpdate: Pick<Structure, 'siret' | 'type'> & { officeType?: 'solo' | 'group' } }) {
    const { href } = new URL(`/structures/${structureId}/administrative-infos`, environment.apiUrl);
    return this.http.put(href, { type, siret, officeType: type === 'office' ? officeType : undefined }).pipe(map((data) => this.mapCallback(data)));
  }

  updatePictures({ structureId, fieldsToUpdate: { picturesToAddOrMove, picturesToDelete } }: { structureId: string; fieldsToUpdate: { picturesToAddOrMove: ({data: string} | {_id: string})[]; picturesToDelete: { _id: string }[] } }) {
    const { href } = new URL(`/structures/${structureId}/update-pictures`, environment.apiUrl);
    return this.http.put(href, { picturesToAddOrMove, picturesToDelete }).pipe(map((data) => this.mapCallback(data)));
  }
}
