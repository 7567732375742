<button
  (click)="catchClick($event)"
  [type]="type()"
  class="inline-flex items-center justify-center rounded-200 border border-solid border-transparent font-montserrat font-600"
  [ngClass]="{
    'text-sm leading-5': size() === 'small',
    'p-2': size() === 'small' && slot() === 'icon-only',
    'px-4 py-2': size() === 'small' && slot() !== 'icon-only',

    'text-base': size() === 'medium',
    'p-3': (size() === 'medium' || size() === 'large') && slot() === 'icon-only',
    'px-5 py-3': size() === 'medium' && slot() !== 'icon-only',

    'text-xl leading-6': size() === 'large',
    'px-8 py-4': size() === 'large' && slot() !== 'icon-only',

    'hover:cursor-not-allowed': disabled(),
    'hover:cursor-pointer': !disabled(),

    '!border-brand-200 bg-brand-200 text-brand-50': variant() === 'primary' && disabled(),
    '!border-brand bg-brand-500 text-white hover:bg-brand-400 active:bg-brand-700': variant() === 'primary' && !disabled(),

    '!border-brand-100 bg-neutral-100 text-brand-150': variant() === 'secondary' && disabled(),
    '!border-brand-200 bg-neutral-100 text-brand-500 hover:bg-brand-0 hover:text-brand-400 active:bg-brand-100 active:text-brand-500': variant() === 'secondary' && !disabled(),

    '!border-neutral-150 bg-neutral-150 text-brand-150': variant() === 'tertiary' && disabled(),
    '!border-brand-0 bg-brand-0 text-brand hover:!border-brand-100 hover:bg-brand-100 hover:text-brand-400 active:!border-brand-200 active:bg-brand-200': variant() === 'tertiary' && !disabled(),

    '!border-emerald-0 bg-emerald-0 text-emerald-200': variant() === 'conversion' && disabled(),
    '!border-emerald-500 bg-emerald-500 text-neutral-100 hover:!border-emerald-600 hover:bg-emerald-600 active:!border-emerald-700 active:bg-emerald-700': variant() === 'conversion' && !disabled(),

    '!border-neutral-100 bg-neutral-100 text-brand-150': variant() === 'ghost' && disabled(),
    '!border-neutral-100 bg-neutral-100 text-brand-500 hover:text-brand-400 active:!border-brand-0 active:bg-brand-0 active:text-brand-400': variant() === 'ghost' && !disabled(),

    '!border-neutral-100 bg-neutral-100 text-error-200': variant() === 'delete' && disabled(),
    '!border-neutral-100 bg-neutral-100 text-error-500 hover:text-error-600 active:!border-error-150 active:bg-error-150': variant() === 'delete' && !disabled(),

    'w-full': block(),
  }"
>
  @if (slot() === "icon-only") {
    <span>
      @if (iconSize()) {
        <app-icon [name]="iconName()!" [strokeWidth]="size() === 'large' ? 2 : 1.5" [size]="iconSize()"></app-icon>
      }
    </span>
  } @else {
    <!-- LEFT ICON -->
    @if (slot() === "left") {
      <span class="mr-2">
        @if (iconSize()) {
          <app-icon [name]="iconName()!" [strokeWidth]="size() === 'large' ? 2 : 1.5" [size]="iconSize()"></app-icon>
        }
      </span>
    }

    <!-- CONTENT-->
    <ng-content></ng-content>

    <!-- RIGHT ICON -->
    @if (slot() === "right") {
      <span class="ml-2">
        @if (iconSize()) {
          <app-icon [name]="iconName()!" [strokeWidth]="size() === 'large' ? 2 : 1.5" [size]="iconSize()"></app-icon>
        }
      </span>
    }
  }
</button>
