import {
  AlertCircle,
  AlertTriangle,
  Banana,
  Bell,
  BellRing,
  Bookmark,
  Briefcase,
  Building2,
  CalendarDays,
  Camera,
  Check,
  CheckCircle2,
  ChevronDown,
  ChevronLeft,
  ChevronRight,
  ChevronUp,
  Clock,
  Compass,
  Cross,
  Edit,
  ExternalLink,
  Eye,
  EyeOff,
  FileText,
  GraduationCap,
  Heart,
  HelpCircle,
  Home,
  Hourglass,
  ImagePlus,
  Info,
  KeyRound,
  LifeBuoy,
  Lock,
  LogOut,
  Mail,
  MapPin,
  MessagesSquare,
  Minus,
  Newspaper,
  Plus,
  QrCode,
  Search,
  Send,
  Settings,
  Share,
  ShieldCheck,
  Sparkles,
  SquarePen,
  Star,
  Trash2,
  User,
  UserCircle,
  UserPlus,
  Users,
  X,
} from 'lucide-angular';

export const icons = {
  Banana,
  Plus,
  Minus,
  Bell,
  Eye,
  EyeOff,
  X,
  Hourglass,
  Info,
  AlertCircle,
  CheckCircle2,
  AlertTriangle,
  Bookmark,
  Sparkles,
  Star,
  Check,
  ExternalLink,
  ChevronUp,
  ChevronRight,
  ChevronDown,
  ChevronLeft,
  MapPin,
  Clock,
  Heart,
  Share,
  User,
  Search,
  Home,
  Briefcase,
  Users,
  GraduationCap,
  UserPlus,
  MessagesSquare,
  UserCircle,
  LogOut,
  LifeBuoy,
  HelpCircle,
  KeyRound,
  BellRing,
  Mail,
  Newspaper,
  ShieldCheck,
  FileText,
  Settings,
  Building2,
  Send,
  QrCode,
  Trash2,
  Edit,
  Lock,
  SquarePen,
  CalendarDays,
  Cross,
  Compass,
  ImagePlus,
  Camera,
};

export type IconNames = keyof typeof icons;
