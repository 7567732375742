import { Routes } from '@angular/router';
import { TabsPage } from './tabs.page';
import { formationRoutes } from '../pages/formations/formation.routes';
import { AuthGuard } from '../guards/auth/auth.guard';
import { profileRoutes } from '../pages/profile/profile.routes';
import { practitionerRoutes } from '../pages/practitioner/practitioner.routes';
import { structureRoutes } from "../pages/structure/structure.routes";

export const tabsRoutes: Routes = [
  {
    path: '',
    component: TabsPage,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'home',
        loadComponent: () => import('../pages/home/home.page').then((m) => m.HomePage),
        title: 'Accueil',
      },
      {
        path: 'job',
        loadComponent: () => import('../pages/job/job.page').then(m => m.JobPage)
      },
      ...formationRoutes,
      ...profileRoutes,
      ...practitionerRoutes,
      ...structureRoutes,
      {
        path: '',
        redirectTo: 'home',
        pathMatch: 'full',
      },
    ],
  },
];
